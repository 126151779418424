import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CrudAccessService {
    private user: any;
    private refRoles: any;
    private allRoles: any;

    constructor() {
        this.getUser();
    }

    public getUser() {
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    public checkCrudRights(authorizeAttribute: string): boolean {
        let retValue = false;
        this.getUser();
        if (this.user && this.user.roles) {
            const userRoles = this.user.roles;
            this.getRefRoles();
            if (this.refRoles) {
                let webComponent = '';
                let webAction = '';
                if (authorizeAttribute.indexOf(',') > -1) {
                    const splitedRole = authorizeAttribute.split(',');
                    webComponent = splitedRole[0];
                    webAction = splitedRole[1];
                } else {
                    webComponent = authorizeAttribute;
                    webAction = null;
                }
                const aRoles = this.refRoles.filter(p => p.component === webComponent && p.action === webAction)[0];
                if (aRoles) {
                    aRoles.roleIds.forEach(e => {
                        const foundRole = userRoles.filter(p => p === e)[0];
                        if (foundRole) {
                            retValue = true;
                        }
                    });
                }
            }
        }
        return retValue;
    }

    public checkRouteRights(authorizeAttributes: string[]): boolean {
        let retValue = false;
        this.getUser();
        if (this.user && this.user.roles) {
            const userRoles = this.user.roles;
            this.getRefRoles();
            if (this.refRoles) {
                authorizeAttributes.forEach(a => {
                    const authorizeAttribute = a;
                    let webComponent = '';
                    let webAction = '';
                    if (authorizeAttribute.indexOf('#') > -1) {
                        const splittedRole = authorizeAttribute.split('#');
                        webComponent = splittedRole[0];
                        webAction = splittedRole[1];
                    } else {
                        webComponent = authorizeAttribute;
                        webAction = null;
                    }
                    const aRoles = this.refRoles.filter(p => p.component === webComponent && p.action === webAction)[0];
                    if (aRoles) {
                        aRoles.roleIds.forEach(e => {
                            const foundRole = userRoles.filter(p => p === e)[0];
                            if (foundRole) {
                                retValue = true;
                            }
                        });
                    }
                });
            }
        }
        return retValue;
    }

    public checkUserHasRole(roleCode: string): boolean {
        let retValue = false;
        this.getUser();
        if (this.user && this.user.roles) {
            const userRoles = this.user.roles;            
            this.getAllRoles();
            if (this.allRoles) {               
                const roleId = this.allRoles.filter(p => p.code === roleCode)[0];              
                if (roleId) {                  
                    if (userRoles) { 
                        userRoles.forEach(e => {
                                if (e === roleId.id) {                                  
                                    retValue = true;
                                }
                            });                
                    }
                }             
            }
        }
        return retValue;
    }

    public getRefRoles() {
        const references = JSON.parse(localStorage.getItem('references')).references;
        if (references && references.roleAccess) {
            this.refRoles = references.roleAccess;
        } else {
            const error = {error: {errors: [{message: `Не найдено!`}]}, status: 404};
            this.refRoles.showError(error);
        }
    }

    public getAllRoles() {
        const references = JSON.parse(localStorage.getItem('references')).references;
        if (references && references.role) {
            this.allRoles = references.role;
        } else {
            const error = {error: {errors: [{message: `Не найдено!`}]}, status: 404};
            this.allRoles.showError(error);
        }
    }


}
