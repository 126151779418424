import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AuthService} from '../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {SystemDataService} from '../services/system-data.service';
import {Router} from '@angular/router';
import {ReferenceService} from '../services/reference.service';
import {RegionService} from '../services/region.service';

@Component({
    selector: 'app-system-layout',
    templateUrl: './system-layout.component.html'
})
export class SystemLayoutComponent implements OnInit {

    userMenu: MenuItem[];
    user: any;
    taskCount = 0;
    notifCount = 0;
    docLink: any;

    constructor(private authService: AuthService,
                public translate: TranslateService,
                private systemDataService: SystemDataService,
                private referenceService: ReferenceService,
                private regionService: RegionService,
                private router: Router) {

    }

    setMenu(translations) {
        this.userMenu = [
            {
                label: `${this.user.firstName} ${this.user.lastName}`,
                items: [
                    {label: translations.global_notifications, icon: 'pi pi-envelope', routerLink: '/account/notifications'},
                    {label: translations.global_tasks, icon: 'pi pi-bell', routerLink: '/account/tasks'},
                    {label: translations.error_log, icon: 'pi pi-exclamation-triangle', routerLink: '/account/errors'},
                    {label: translations.password_change, icon: 'pi pi-unlock', routerLink: '/account/change-password'},
                    {label: translations.global_logout, icon: 'pi pi-sign-out', command: () => this.logout()}
                ]
            }
        ];
    }

    public getCounts() {
        this.systemDataService.getTaskNotificationCount().then(
            r => {
                this.taskCount = r.tasks;
                this.notifCount = r.notifications;
            }
        );
    }

    ngOnInit() {
        this.referenceService.getAllReferences();
        this.regionService.saveUserRegionsToLocalStorage();
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getCounts();

        this.translate.getTranslation(this.translate.currentLang)
            .toPromise().then(translations => {
            this.setMenu(translations);
        });

        this.translate.onLangChange.subscribe(translate => (
            this.setMenu(translate.translations)
        ));

        this.docLink = location.origin + '/documentation/web';
    }

    setLang(lang: string) {
        this.translate.use(lang);
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/home']);
    }

}
