import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html'
})
export class LoginPageComponent implements OnInit, OnDestroy {

    loginForm: FormGroup;
    subs: Subscription;

    constructor(private authService: AuthService,
                private router: Router) {
    }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['/system-home']);
        }

        this.loginForm = new FormGroup({
            userLogin: new FormControl(null, [Validators.required]),
            password: new FormControl(null, [Validators.required])
        });
    }

    onSubmit() {
        if (this.loginForm.valid) {
            this.subs = this.authService.login(this.loginForm.value).subscribe(
                () => this.router.navigate(['/system-home'])
            );
        }
    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }

}
