import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {RegionService} from '../../shared/services/region.service';
import {ReferenceService} from '../../shared/services/reference.service';
import {SelectOptionFormatService} from '../../shared/services/helpers/select-option-format.service';
import {AnonymousService} from '../../shared/services/anonymous.service';
import {ReCaptchaV3Service} from 'ngx-captcha';
import {ReCaptchaKey} from '../../shared/const/reCaptchaKey';
import {DomSanitizer} from '@angular/platform-browser';
import {RegionTreeService} from '../../shared/services/helpers/regionTree.service';

@Component({
    selector: 'app-vet-search',
    templateUrl: './vet-search.component.html'
})
export class VetSearchComponent implements OnInit {

    siteKey = ReCaptchaKey.siteKey;
    searchForm: FormGroup;
    rayons: any;
    aiylAimak: any;
    villages: any;
    vetList: any;
    vetDetail: any;
    workExpLabelRu = '';
    workExpLabelKy = 'жыл';
    displayDetailDialog = false;
    totalRegions = [];

    constructor(public translate: TranslateService,
                private regionService: RegionService,
                private referenceService: ReferenceService,
                private anonymousService: AnonymousService,
                private reCaptchaV3Service: ReCaptchaV3Service,
                private sanitizer: DomSanitizer) {
    }

    async getRayons() {
        const response = await this.regionService.getRegionsByParams(null, 4, 3, false);
        this.rayons = SelectOptionFormatService.getOptions(response);
    }

    async getAiylAimak(parentId) {
        const response = await this.regionService.getRegionsByParams(parentId, 6, null, false);
        this.aiylAimak = SelectOptionFormatService.getOptions(response);
    }

    async getVillages(parentId) {
        const response = await this.regionService.getRegionsByParams(parentId, null, null, false);
        this.villages = SelectOptionFormatService.getOptions(response);
    }

    onRayonSelect(event) {
        this.searchForm.controls.ayilAymak.setValue(null);
        if (event.value) {
            this.aiylAimak = null;
            this.villages = null;
            this.getAiylAimak(event.value);
        } else {
            this.aiylAimak = null;
            this.villages = null;
        }
    }

    onAiylAimakSelect(event) {
        this.searchForm.controls.village.setValue(null);
        if (event.value) {
            this.villages = null;
            this.getVillages(event.value);
        } else {
            this.villages = null;
        }
    }

    async ngOnInit() {
        await this.getRayons();

        this.searchForm = new FormGroup({
            rayon: new FormControl(null, [Validators.required]),
            ayilAymak: new FormControl(null),
            village: new FormControl(null),
        });
    }

    async onSubmit() {
        this.vetList = null;
        const formValue = {...this.searchForm.value};
        let regionId = '';

        if (formValue.rayon) {
            regionId = formValue.rayon;
        }
        if (formValue.ayilAymak) {
            regionId = formValue.ayilAymak;
        }
        if (formValue.village) {
            regionId = formValue.village;
        }

        this.reCaptchaV3Service.execute(this.siteKey, 'AITSVeterinarySearch', async (token) => {
            this.vetList = await this.anonymousService.getVeterinariansByRegion(regionId);
        }, {
            useGlobalDomain: false
        });
    }

    async getVeterinarian(id: string) {
        this.vetDetail = await this.anonymousService.getVeterinarian(id);
        this.vetDetail.regionsTree = [];
        this.vetDetail.specList = [];

        if (this.vetDetail) {
            if (this.vetDetail.vetUser) {
                if (this.vetDetail.vetUser.photo) {
                    this.vetDetail.vetUser.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.vetDetail.vetUser.photo);
                }
                if (this.vetDetail.vetUser.workExperience) {
                    const y = this.vetDetail.vetUser.workExperience;
                    if (y === 1) {
                        this.workExpLabelRu = 'год';
                    } else if (y > 1 && y < 5) {
                        this.workExpLabelRu = 'года';
                    } else {
                        this.workExpLabelRu = 'лет';
                    }
                }
                this.referenceService.educationRefList.forEach(e => {
                    if (e.value === this.vetDetail.vetUser.education) {
                        this.vetDetail.vetUser.educationLabel = e;
                    }
                });
            }
            if (this.vetDetail.userRegions && this.vetDetail.userRegions.length) {
                this.totalRegions = [];
                this.vetDetail.userRegions.forEach(r => this.collectRegions(r));
                this.vetDetail.regionsTree = RegionTreeService.listToTreeExpanded(this.totalRegions, 8);
            }
            if (this.vetDetail.userSpec && this.vetDetail.userSpec.length) {
                this.vetDetail.specList = this.vetDetail.userSpec.map(spec => {
                    return this.referenceService.getReference('spec', spec.specId);
                });
            }
        }

        this.showDetailDialog();
    }

    showDetailDialog() {
        this.displayDetailDialog = true;
    }

    hideDetailDialog() {
        this.vetDetail = null;
        this.displayDetailDialog = false;
    }

    private collectRegions(regionId) {
        const region = this.referenceService.getReference('region', regionId);
        if (this.totalRegions.findIndex(r => r.id === region.id) === -1) {
            this.totalRegions.push(region);
        }
        if (region.regionTypeId > 2) {
            this.collectRegions(region.parentId);
        }
    }

}
