import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from './services/auth.service';
import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService,
                private router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const authorizeAttribute = route.data.authorizeAttribute;
        if (this.authService.isAuthorized(authorizeAttribute)) {
            return of(true);
        } else {
            this.router.navigate(['/login']);
            return of(false);
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const authorizeAttribute = childRoute.data.authorizeAttribute;
        if (this.authService.isAuthorized(authorizeAttribute)) {
            return of(true);
        } else {
            this.router.navigate(['/login']);
            return of(false);
        }
    }
}
