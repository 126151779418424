import {Component} from '@angular/core';

@Component({
    selector: 'app-inner-loader',
    templateUrl: './inner-loader.component.html'
})
export class InnerLoaderComponent {
}


