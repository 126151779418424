import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
    providedIn: 'root'
})
export class AnonymousService extends BaseApi {

    requestRegister = (request: object): Promise<any> => this.post('/Application/RegisterAnimalByOwnerOrKeeper', request);
    requestDeregister = (request: object): Promise<any> => this.post('/Application/Deregister', request);
    requestDeparture = (request: object): Promise<any> => this.post('/Application/AnimalDepartureByOwnerOrKeeper', request);
    requestArrival = (request: object): Promise<any> => this.post('/Application/AnimalArrivalByOwnerOrKeeper', request);

    docCheck = (id: string, token: string): Promise<any> => this.get(`/Document/check/${id}?captchaToken=${token}`);
    getDocData = (url: string): Promise<any> => this.get(`/${url}`);

    getVeterinariansByRegion = (regionId: string): Promise<any> => this.get(`/User/VeterinarSearch?regionId=${regionId}`);
    getVeterinarian = (id: string): Promise<any> => this.get(`/User/VeterinarDetail?id=${id}`);

    qrCheck = (id: string): Promise<any> => this.get(`/ProductMovement/DocumentByCode?code=${id}`);
    animalCheck = (tagNumber: string): Promise<any> => this.get(`/Animal/qrcheck/${tagNumber}`);

    getIdentCountByRegionAndSpecies = (regionId: string, speciesId: string): Promise<any> => this.get(`/Data/AllAnimalsBySpecies?regionId=${regionId}&speciesId=${speciesId}`);
    
}
