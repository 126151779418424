import {Component, OnInit} from '@angular/core';
import {ReferenceService} from '../services/reference.service';

@Component({
    selector: 'app-anonymous-layout',
    templateUrl: './anonymous-layout.component.html'
})
export class AnonymousLayoutComponent implements OnInit {

    constructor(private referenceService: ReferenceService) {
    }

    ngOnInit() {
        this.referenceService.getAllReferences();
    }

}
