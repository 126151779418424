import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AnonymousService} from '../../shared/services/anonymous.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ReferenceService} from '../../shared/services/reference.service';

@Component({
    selector: 'app-qr-check',
    templateUrl: './qr-check.component.html'
})
export class QrCheckComponent implements OnInit {

   
    documentType: any;
    animalData: any;
    documentData2: any;
    documentData3: any;

    constructor(public translate: TranslateService,
                private anonymousService: AnonymousService,              
                private activatedRoute: ActivatedRoute,
                private referenceService: ReferenceService,
                private router: Router) {
    }

    async ngOnInit() {       
        this.documentData3 = null;
        this.animalData = null;
        const docId = this.activatedRoute.snapshot.queryParams.code;
        if (docId) {            
            await this.getDocumentData(docId);
        }
    }
    

    async updateUrl(code: string) {
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {code},
            queryParamsHandling: 'merge',
            skipLocationChange: false
        });
    }
    async getDocumentData(docId: string) {

        const documentData = await this.anonymousService.qrCheck(docId);
            documentData.documentType = this.referenceService.getReference('documentType', 3);
       
        let tagNumber = '';
        if(documentData.productParts){
    
            tagNumber = documentData.productParts[0].tagNumber;
        
            this.documentData3 = documentData;
            if(tagNumber){
                await this.getAnimalData(tagNumber);
            }
        }
    }

    async getAnimalData(tagNumber: string) {
        this.animalData = null;
        
            this.animalData = await this.anonymousService.animalCheck(tagNumber);      
            this.animalData.status = this.referenceService.getReference('animalStatus', this.animalData.animalStatusId);
            this.animalData.healthStatus = this.referenceService.getReference('animalHealthStatus', this.animalData.animalHealthStatusId);
            this.animalData.clarifyingStatus = this.referenceService.getReference('animalClarifyingStatus', this.animalData.animalClarifyingStatusId);
          
    }

   
}
