import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';
import {ToastService} from './helpers/toast.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ReferenceService extends BaseApi {
    constructor(public http: HttpClient,
                private toastService: ToastService) {
        super(http);
    }

    public genderOptions = {
        ru: [
            {label: 'Самец', value: true},
            {label: 'Самка', value: false}
        ],
        ky: [
            {label: 'Эркек', value: true},
            {label: 'Ургаачы', value: false}
        ]
    };

    public subjectStatusOptions = {
        ru: [
            {label: 'Активный', value: true},
            {label: 'Неактивный', value: false},
        ],
        ky: [
            {label: 'Активдүү', value: true},
            {label: 'Активдүү эмес', value: false},
        ]
    };

    public genderUserOptions = {
        ru: [
            {label: 'Мужской', value: true},
            {label: 'Женский', value: false}
        ],
        ky: [
            {label: 'Эркек', value: true},
            {label: 'Аял', value: false}
        ]
    };

    public educationOptions = {
        ru: [
            {label: 'Высшее', value: 1},
            {label: 'Средне специальный', value: 2}
        ],
        ky: [
            {label: 'Жогорку', value: 1},
            {label: 'Орто кесиптик', value: 2}
        ]
    };

    public educationRefList = [
        {value: 1, nameRu: 'Высшее', nameKy: 'Жогорку'},
        {value: 2, nameRu: 'Средне специальный', nameKy: 'Орто кесиптик'},
    ];

    public genderUserRefList = [
        {value: true, nameRu: 'Мужской', nameKy: 'Эркек'},
        {value: false, nameRu: 'Женский', nameKy: 'Аял'},
    ];

    getAllReferences = async (): Promise<any> => {
        let date = '1970-01-01T00:00:00';
        const references = localStorage.getItem('references');

        if (references) {
            date = JSON.parse(references).date;
        }
        const dateURI = encodeURIComponent(date);

        const response = await this.get(`/Account/reference/${dateURI}`);

        if (response.updated) {
            localStorage.setItem('references', JSON.stringify(response));
        }
    };

    loadFromLocalStorage(refName) {
        const references = JSON.parse(localStorage.getItem('references')).references;

        if (references && references[refName]) {
            return references[refName];
        } else {
            console.error(`${refName} not found!`);
            const error = {error: {errors: [{message: `Справочник не найден!`}]}, status: 404};
            this.toastService.showError(error);
        }
    }

    getReferenceList = (ref: string) => {
        return this.loadFromLocalStorage(ref);
    };

    getReference = (name: string, id: any) => {
        const refList = this.loadFromLocalStorage(name);
        // tslint:disable-next-line:triple-equals
        return refList.find(r => r.id == id);
    };

    addReference = async (name: string, ref: object): Promise<any> => {
        await this.post(`/${name}`, ref);
        return this.getAllReferences();
    };

    editReference = async (name: string, ref: object): Promise<any> => {
        await this.put(`/${name}`, ref);
        return this.getAllReferences();
    };

    deleteReference = async (name: string, id: number): Promise<any> => {
        await this.delete(`/${name}/${id}`);
        return this.getAllReferences();
    };

    getReferenceGender = (id: any) => {
        const refList = [
            {id: true, nameKy: 'Эркек', nameRu: 'Самец'},
            {id: false, nameKy: 'Ургаачы', nameRu: 'Самка'},
        ];
        return refList.find(r => r.id === id);
    };

    getAgeFromMonth = (months: number) => {
        let year = 0;
        let month = 0;
        let yearStr = 'лет';
        let monthStr = 'месяцев';
        if (months > 0) {
            year = Math.floor(months / 12);
            month = months % 12;
            if (year === 1) {
                yearStr = 'год';
            } else if (year < 5) {
                yearStr = 'года';
            }
        }
        let retList: any;
        if (month > 0) {
            if (month === 1) {
                monthStr = 'месяц';
            } else if (month > 1 && month < 5) {
                monthStr = 'месяца';
            }
            retList = {nameRu: year + ' ' + yearStr + ' ' + month + ' ' + monthStr, nameKy: year + ' жаш ' + month + ' ай'};
        } else {
            retList = {nameRu: year + ' ' + yearStr, nameKy: year + ' жаш'};
        }
        return retList;
    }

    isFloat(n) {
        // tslint:disable-next-line: no-bitwise
        return n === +n && n !== (n | 0);
    }

    getReferenceCritical = (id: any) => {
        const refList = [
            {id: true, nameKy: 'Кооптуу', nameRu: 'Критический'},
            {id: false, nameKy: 'Кооптуу эмес', nameRu: 'Не критический'},
        ];
        return refList.find(r => r.id === id);
    };

    deleteRoleExtended = async (id: number): Promise<any> => {
        await this.delete(`/Role/DeleteExtended?id=${id}`);
        return this.getAllReferences();
    };

    insertMedicine = (medicine: object): Promise<any> => this.post('/MedicineMovement/InsertMedicine', medicine);

    insertMedicineList = (medicineList: object): Promise<any> => this.post('/MedicineMovement/InsertMedicineList', medicineList);

}
