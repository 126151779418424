import { Injectable } from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class SystemDataService extends BaseApi {

    getCounts = (): Promise<any> => this.get('/system/count');

    getTaskNotificationCount = (): Promise<any> => this.get('/Account/TaskNotificationCount');

    getAppSettings = (): Promise<any> => this.get('/System/ApplicationSettings');

    getParams = (): Promise<any> => this.get('/System/Parameters');
    updateParam = (paramData: object): Promise<any> => this.put('/System/UpdateParameter', paramData);

    checkAuth = (): Promise<any> => this.post('/System/CheckAuthentication', {});

    clearAccessCache = (): Promise<any> => this.post('/System/ClearAccessCache', {});
    clearAppSettingsCache = (): Promise<any> => this.post('/System/ClearApplicationSettingsCache', {});
    clearReferenceDataCache = (): Promise<any> => this.post('/System/ClearReferenceDataCache', {});
}
