import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private messageService: MessageService) {
    }

    showSuccess(success): void {
        if (success.body && success.body.successes) {
            this.messageService.clear();
            success.body.successes.forEach(s => {
                if (s.show) {
                    this.messageService.add({
                        life: 5000,
                        severity: 'success',
                        detail: s.message
                    });
                }
            });
        }
    }

    showError(error): void {
        const toastType = {
            life: 40000,
            severity: 'error',
        };

        switch (error.status) {
            case 400:
            case 403:
            case 404:
            case 500:
                error.error.errors.forEach(e => {
                    this.messageService.add({
                        ...toastType,
                        detail: e.message
                    });
                });
                break;
            case 401:
                this.messageService.clear();
                this.messageService.add({
                    ...toastType,
                    detail: 'Ваша сессия устарела, авторизуйтесь заново',
                    life: 5000
                });
                break;
            default:
                this.messageService.clear();
                this.messageService.add({
                    ...toastType,
                    detail: 'Сервер недоступен'
                });
        }
    }
}
