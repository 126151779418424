import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './shared/services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {Router, RouteConfigLoadStart, RouteConfigLoadEnd} from '@angular/router';
import {LoaderService} from './shared/services/helpers/loader.service';
import {SystemDataService} from './shared/services/system-data.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

    subs: Subscription;

    constructor(private translate: TranslateService,
                private titleService: Title,
                private router: Router,
                private loaderService: LoaderService,
                private authService: AuthService,
                @Inject(DOCUMENT) private document: Document) {
        translate.setDefaultLang('ru');
        translate.use(localStorage.getItem('userLang') || 'ru');
        translate.onLangChange.subscribe(
            l => {
                this.document.documentElement.lang = l.lang;
                this.document.title = l.translations.global_system_title;
                localStorage.setItem('userLang', l.lang);
            }
        );
    }

    ngOnInit() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            this.authService.setToken(user.token);
        }
        this.subs = this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart) {
                this.loaderService.show();
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loaderService.hide();
            }
        });
    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
}
