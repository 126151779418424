import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

import { AppComponent } from './app.component';
import { LoginPageComponent } from './shared/components/login-page/login-page.component';
import { MainHttpInterceptor } from './shared/main-http.interceptor';
import { SystemLayoutComponent } from './shared/system-layout/system-layout.component';
import { SystemHomePageComponent } from './shared/components/system-home-page/system-home-page.component';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';

import { AppRoutingModule } from './app-routing.module';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {HomePageComponent} from './shared/components/home-page/home-page.component';
import { RequestRegisterComponent } from './anonymous/requests/request-register/request-register.component';
import {AnonymousLayoutComponent} from './shared/anonymous-layout/anonymous-layout.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import { RequestMoveComponent } from './anonymous/requests/request-move/request-move.component';
import { RequestDeregisterComponent } from './anonymous/requests/request-deregister/request-deregister.component';
import { AnimalCheckComponent } from './anonymous/animal-check/animal-check.component';
import { DocCheckComponent } from './anonymous/doc-check/doc-check.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import { VetSearchComponent } from './anonymous/vet-search/vet-search.component';
import { IdentCountComponent } from './anonymous/identification/ident-count.component';
import { QrCheckComponent } from './anonymous/qr-check/qr-check.component';


registerLocaleData(localeRu, 'ru');

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        LoginPageComponent,
        AnonymousLayoutComponent,
        SystemLayoutComponent,
        SystemHomePageComponent,
        NotFoundPageComponent,
        RequestRegisterComponent,
        RequestMoveComponent,
        RequestDeregisterComponent,
        AnimalCheckComponent,
        DocCheckComponent,
        VetSearchComponent,
        IdentCountComponent,
        QrCheckComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        NgxCaptchaModule,
        ZXingScannerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        MessageService,
        ConfirmationService,
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: MainHttpInterceptor
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
