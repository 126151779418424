import {Injectable} from '@angular/core';
import {User} from '../interfaces';
import {BaseApi} from '../base-api';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {CrudAccessService} from './helpers/crud-access.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ErrorService} from './error.service';
import {RegionService} from './region.service';


@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseApi {
    constructor(private crudAccessService: CrudAccessService,
                private errorService: ErrorService,
                private regionsService: RegionService,
                public http: HttpClient,
                public router: Router) {
        super(http);
    }

    private token = null;

    login(user: User): Observable<any> {
        return this.auth('/authenticate', user)
            .pipe(
                tap(
                    response => {
                        localStorage.setItem('user', JSON.stringify(response));
                        this.setToken(response.token);
                        this.errorService.checkUserErrors();
                        this.crudAccessService.getUser();
                    }
                )
            );
    }

    setToken(token: string) {
        this.token = token;
    }

    getToken(): string {
        return this.token;
    }

    isAuthenticated(): boolean {
        return !!this.token;
    }

    isAuthorized(authorizeAttribute: string[]): boolean {
        //check token if empty return
        if (!this.token) {
            return false;
        }
        // if there is no Role then like allowanonymous this will work
        if (authorizeAttribute == null || authorizeAttribute.length === 0) {
            return true;
        }
        // check roles from LocalStorage
        return this.crudAccessService.checkRouteRights(authorizeAttribute);
    }

    logout() {
        this.setToken(null);
        localStorage.setItem('user', null);
        this.regionsService.clearUserRegionsFromLocalStorage();
        this.router.navigate(['/login']);
    }

}
