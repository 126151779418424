import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class BaseApi {

    private apiUrl = environment.apiUrl;

    constructor(public http: HttpClient) {}

    private getUrl = (url: string = ''): string => {
        return this.apiUrl + url;
    };

    public auth(url: string = '', data: any = {}, options: any = {}): Observable<any> {
        return this.http.post(this.apiUrl + '/account' + url, data, options);
    }

    public get(url: string = ''): Promise<any> {
        return this.http.get<any>(this.getUrl(url)).toPromise();
    }

    public post(url: string = '', data: any = {}, options: any = {}): Promise<any> {
        return this.http.post(this.getUrl(url), data, options).toPromise();
    }

    public put(url: string = '', data: any = {}, options: any = {}): Promise<any> {
        return this.http.put(this.getUrl(url), data, options).toPromise();
    }

    public delete(url: string = '', options: any = {}): Promise<any> {
        return this.http.delete(this.getUrl(url), options).toPromise();
    }

}
