import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './shared/auth.guard';
import {LoginPageComponent} from './shared/components/login-page/login-page.component';
import {SystemLayoutComponent} from './shared/system-layout/system-layout.component';
import {SystemHomePageComponent} from './shared/components/system-home-page/system-home-page.component';
import {NotFoundPageComponent} from './shared/components/not-found-page/not-found-page.component';
import {HomePageComponent} from './shared/components/home-page/home-page.component';
import {RequestRegisterComponent} from './anonymous/requests/request-register/request-register.component';
import {AnonymousLayoutComponent} from './shared/anonymous-layout/anonymous-layout.component';
import {RequestMoveComponent} from './anonymous/requests/request-move/request-move.component';
import {RequestDeregisterComponent} from './anonymous/requests/request-deregister/request-deregister.component';
import {AnimalCheckComponent} from './anonymous/animal-check/animal-check.component';
import {DocCheckComponent} from './anonymous/doc-check/doc-check.component';
import {VetSearchComponent} from './anonymous/vet-search/vet-search.component';
import {IdentCountComponent} from './anonymous/identification/ident-count.component';
import {QrCheckComponent} from './anonymous/qr-check/qr-check.component';

const routes: Routes = [

    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'login', component: LoginPageComponent},
    {
        path: '',
        component: AnonymousLayoutComponent,
        children: [
            {path: 'home', component: HomePageComponent},
            {path: 'requestRegister', component: RequestRegisterComponent},
            {path: 'requestDeregister', component: RequestDeregisterComponent},
            {path: 'requestArrival', component: RequestMoveComponent, data: {action: 'arrival'}},
            {path: 'requestDeparture', component: RequestMoveComponent, data: {action: 'departure'}},
            {path: 'animal-check', component: AnimalCheckComponent},
            {path: 'doc-check', component: DocCheckComponent},
            {path: 'veterinarians', component: VetSearchComponent},
            {path: 'qr-check', component: QrCheckComponent},
            {path: 'ident-count', component: IdentCountComponent},
        ]
    },
    {
        path: '',
        component: SystemLayoutComponent,
        canActivateChild: [AuthGuard],
        children: [
            {path: 'system-home', component: SystemHomePageComponent},
            {path: 'identification', loadChildren: './identification/identification.module#IdentificationModule'},
            {path: 'health', loadChildren: './health/health.module#HealthModule'},
            {path: 'profile', loadChildren: './profile/profile.module#ProfileModule'},
            {path: 'movements', loadChildren: './movements/movements.module#MovementsModule'},
            {path: 'products', loadChildren: './products/products.module#ProductsModule'},
            {path: 'laboratory', loadChildren: './laboratory/laboratory.module#LaboratoryModule'},
            {path: 'reports', loadChildren: './reports/reports.module#ReportsModule'},
            {path: 'admin', loadChildren: './admin/admin.module#AdminModule'},
            {path: 'account', loadChildren: './account/account.module#AccountModule'}
        ]
    },
    {path: 'not-found', component: NotFoundPageComponent},
    {path: '**', redirectTo: '/not-found'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
