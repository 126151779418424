import {Component, OnInit} from '@angular/core';
import {SystemDataService} from '../../services/system-data.service';
import {CrudAccessService} from '../../services/helpers/crud-access.service';

@Component({
    selector: 'app-system-home-page',
    templateUrl: './system-home-page.component.html'
})
export class SystemHomePageComponent implements OnInit {

    homeMenu = [];

    constructor(private systemDataService: SystemDataService,
                private crudAccessService: CrudAccessService) {}

    async ngOnInit() {
        const systemCounts = await this.systemDataService.getCounts();

        if (this.crudAccessService.checkRouteRights(['animal#get'])) {
            this.homeMenu.push(
                {title: 'global_animals', subtitle: `(${systemCounts.animalCount})`, link: '/identification/animal', icon: 'pi pi-tags'},
            );
        }
        if (this.crudAccessService.checkRouteRights(['subject#get'])) {
            this.homeMenu.push(
                {title: 'global_subjects', subtitle: `(${systemCounts.subjectCount})`, link: '/identification/subject', icon: 'pi pi-users'}
            );
        }
        if (this.crudAccessService.checkRouteRights(['object#get'])) {
            this.homeMenu.push(
                {title: 'global_objects', subtitle: `(${systemCounts.objectCount})`, link: '/identification/object', icon: 'pi pi-home'}
            );
        }
    }
}
