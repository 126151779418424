import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegionTreeService {

    public static listToTree(list) {
        const tree = [];
        const childrenOf = {};

        for (const item of list) {

            item.initialCount = item.count;
            if (item.count !== null) {
                if (!item.selectedCount) {
                    item.selectedCount = 0;
                }
                if (!item.selectedFirstQuarter) {
                    item.selectedFirstQuarter = 0;
                }
                if (!item.selectedSecondQuarter) {
                    item.selectedSecondQuarter = 0;
                }
                if (!item.selectedThirdQuarter) {
                    item.selectedThirdQuarter = 0;
                }
                if (!item.selectedFourthQuarter) {
                    item.selectedFourthQuarter = 0;
                }
            }

            const id = item.id;
            const parentId = item.parentId;

            childrenOf[id] = childrenOf[id] || [];
            item.children = childrenOf[id];

            if (parentId) {
                const parentNode = list.filter(t => t.id === parentId)[0];
                if (parentNode) {
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    childrenOf[parentId].push(item);
                } else {
                    item.expanded = true;
                    tree.push(item);
                }
            } else {
                item.expanded = true;
                tree.push(item);
            }
        }
        return tree;
    }

    public static listToTreeExpanded(list, expandLevel= 4) {
        const tree = [];
        const childrenOf = {};

        for (const item of list) {

            const id = item.id;
            const parentId = item.parentId;

            childrenOf[id] = childrenOf[id] || [];
            item.children = childrenOf[id];

            if (parentId) {
                const parentNode = list.filter(t => t.id === parentId)[0];
                if (parentNode) {
                    childrenOf[parentId] = childrenOf[parentId] || [];
                    if (item.regionLevel < expandLevel) { item.expanded = true; }
                    childrenOf[parentId].push(item);
                } else {
                    if (item.regionLevel < expandLevel ) { item.expanded = true; }
                    tree.push(item);
                }
            } else {
                if (item.regionLevel < expandLevel ) { item.expanded = true; }
                tree.push(item);
            }
        }
        return tree;
    }

    public static changeTreeSelectedCount(region, quarter: number) {
        if (region.regionType !== 1) {
            switch (quarter) {
                case 1:
                    region.parent.selectedCount = 0;
                    region.parent.selectedFirstQuarter = 0;
                    region.parent.children.forEach(child => {
                        region.parent.selectedFirstQuarter += child.selectedFirstQuarter;
                        region.parent.selectedCount += child.selectedCount;
                    });
                    this.changeTreeSelectedCount(region.parent, 1);
                    break;
                case 2:
                    region.parent.selectedCount = 0;
                    region.parent.selectedSecondQuarter = 0;
                    region.parent.children.forEach(child => {
                        region.parent.selectedSecondQuarter += child.selectedSecondQuarter;
                        region.parent.selectedCount += child.selectedCount;
                    });
                    this.changeTreeSelectedCount(region.parent, 2);
                    break;
                case 3:
                    region.parent.selectedCount = 0;
                    region.parent.selectedThirdQuarter = 0;
                    region.parent.children.forEach(child => {
                        region.parent.selectedThirdQuarter += child.selectedThirdQuarter;
                        region.parent.selectedCount += child.selectedCount;
                    });
                    this.changeTreeSelectedCount(region.parent, 3);
                    break;
                case 4:
                    region.parent.selectedCount = 0;
                    region.parent.selectedFourthQuarter = 0;
                    region.parent.children.forEach(child => {
                        region.parent.selectedFourthQuarter += child.selectedFourthQuarter;
                        region.parent.selectedCount += child.selectedCount;
                    });
                    this.changeTreeSelectedCount(region.parent, 4);
                    break;
            }
        }
    }

    public static iterateTreeSelect(rootRegion) {
        rootRegion.selectedFirstQuarter = rootRegion.firstQuarter;
        rootRegion.selectedSecondQuarter = rootRegion.secondQuarter;
        rootRegion.selectedThirdQuarter = rootRegion.thirdQuarter;
        rootRegion.selectedFourthQuarter = rootRegion.fourthQuarter;
        rootRegion.selectedCount = rootRegion.count;
        if (rootRegion.children.length) {
            rootRegion.children.forEach(child => {
                child.selectedFirstQuarter = child.firstQuarter;
                child.selectedSecondQuarter = child.secondQuarter;
                child.selectedThirdQuarter = child.thirdQuarter;
                child.selectedFourthQuarter = child.fourthQuarter;
                child.selectedCount = child.count;
                this.iterateTreeSelect(child);
            });
        }
    }

    public static iterateTreeUnselect(rootRegion) {
        rootRegion.selectedFirstQuarter = 0;
        rootRegion.selectedSecondQuarter = 0;
        rootRegion.selectedThirdQuarter = 0;
        rootRegion.selectedFourthQuarter = 0;
        rootRegion.selectedCount = 0;
        if (rootRegion.children.length) {
            rootRegion.children.forEach(child => {
                child.selectedFirstQuarter = 0;
                child.selectedSecondQuarter = 0;
                child.selectedThirdQuarter = 0;
                child.selectedFourthQuarter = 0;
                child.selectedCount = 0;
                this.iterateTreeUnselect(child);
            });
        }
    }
}
