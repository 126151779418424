import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
    providedIn: 'root'
})
export class ErrorService extends BaseApi {
    loadErrorsFromLocalStorage() {
        return JSON.parse(localStorage.getItem('userErrors'));
    }

    saveToLocalStorage(error) {
        const newErrors = [];
        error.error.errors.forEach(e => {
            newErrors.unshift({
                date: new Date().toISOString(),
                message: e.message
            });
        });

        const userErrors = this.loadErrorsFromLocalStorage();
        userErrors.errorList = [...newErrors, ...userErrors.errorList];

        if (userErrors.errorList.length > 50) {
            userErrors.errorList.length = 50;
        }
        localStorage.setItem('userErrors', JSON.stringify(userErrors));
    }

    checkUserErrors() {
        const userErrors = this.loadErrorsFromLocalStorage();
        const currentUser = JSON.parse(localStorage.getItem('user'));
        if (userErrors) {
            if (currentUser && currentUser.id !== userErrors.userId) {
                localStorage.setItem('userErrors', JSON.stringify({userId: currentUser.id, errorList: []}));
            }
        } else {
            localStorage.setItem('userErrors', JSON.stringify({userId: currentUser.id, errorList: []}));
        }
    }

    getById = (id: string): Promise<any> => this.get(`/System/error/${id}`);
    getByPeriod = (from: string, to: string, pageIndex: number): Promise<any> => this.get(`/System/ErrorsBetween?from=${from}&to=${to}&pageIndex=${pageIndex}`);
}
