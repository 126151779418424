import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthService} from './services/auth.service';
import {Observable} from 'rxjs';
import {ToastService} from './services/helpers/toast.service';
import {LoaderService} from './services/helpers/loader.service';
import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ErrorService} from './services/error.service';
import {systemData} from './const/system-data';

@Injectable()
export class MainHttpInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,
                private errorService: ErrorService,
                private toastService: ToastService,
                private loaderService: LoaderService,
                private translate: TranslateService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.loaderService.show();

        const headers = {
            'Api-Version': '1',
            'Api-Client': `Web/${systemData.clientVersion}`,
            'Accept-Language': this.translate.currentLang,
            Authorization: null
        };

        if (this.authService.isAuthenticated()) {
            headers.Authorization = 'Bearer ' + this.authService.getToken();
            req = req.clone({setHeaders: headers});
        }

        return next.handle(req)
            .pipe(
                tap((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            this.loaderService.hide();
                            this.toastService.showSuccess(event);
                        }
                    },
                    (error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            this.authService.logout();
                        } else if (error.status === 500) {
                            this.errorService.saveToLocalStorage(error);
                        }
                        this.loaderService.hide();
                        this.toastService.showError(error);

                        // return throwError(error.message);
                    }
                )
            );
    }
}
