import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {RegionService} from '../../shared/services/region.service';
import {ReferenceService} from '../../shared/services/reference.service';
import {SelectOptionFormatService} from '../../shared/services/helpers/select-option-format.service';
import {AnonymousService} from '../../shared/services/anonymous.service';
import {ReCaptchaV3Service} from 'ngx-captcha';
import {ReCaptchaKey} from '../../shared/const/reCaptchaKey';
import {DomSanitizer} from '@angular/platform-browser';
import {RegionTreeService} from '../../shared/services/helpers/regionTree.service';

@Component({
    selector: 'app-ident-count',
    templateUrl: './ident-count.component.html'
})
export class IdentCountComponent implements OnInit {

    siteKey = ReCaptchaKey.siteKey;
    searchForm: FormGroup;
    rayons: any;
    aiylAimak: any;
    villages: any;
    documentData1: any;
    totalRegions = [];
    species: any;

    constructor(public translate: TranslateService,
                private regionService: RegionService,
                private referenceService: ReferenceService,
                private anonymousService: AnonymousService,
                private reCaptchaV3Service: ReCaptchaV3Service,
                private sanitizer: DomSanitizer) {
    }

    async getRayons() {
        const response = await this.regionService.getRegionsByParams(null, 4, 3, false);
        this.rayons = SelectOptionFormatService.getOptions(response);
    }

    async getAiylAimak(parentId) {
        const response = await this.regionService.getRegionsByParams(parentId, 6, null, false);
        this.aiylAimak = SelectOptionFormatService.getOptions(response);
    }

    async getVillages(parentId) {
        const response = await this.regionService.getRegionsByParams(parentId, null, null, false);
        this.villages = SelectOptionFormatService.getOptions(response);
    }

    onRayonSelect(event) {
        this.searchForm.controls.ayilAymak.setValue(null);
        if (event.value) {
            this.aiylAimak = null;
            this.villages = null;
            this.getAiylAimak(event.value);
        } else {
            this.aiylAimak = null;
            this.villages = null;
        }
    }

    onAiylAimakSelect(event) {
        this.searchForm.controls.village.setValue(null);
        if (event.value) {
            this.villages = null;
            this.getVillages(event.value);
        } else {
            this.villages = null;
        }
    }

    async ngOnInit() {

        const refSpecies = this.referenceService.getReferenceList('species');
        this.species = SelectOptionFormatService.getOptions(refSpecies);

        await this.getRayons();


        this.searchForm = new FormGroup({
            rayon: new FormControl(null, [Validators.required]),
            ayilAymak: new FormControl(null),
            village: new FormControl(null),
            speciesId: new FormControl(null),
        });
    }

    async onSubmit() {
        this.documentData1 = null;
        const formValue = {...this.searchForm.value};
        let regionId = '';
        let sId = '';

        if (formValue.rayon) {
            regionId = formValue.rayon;
        }
        if (formValue.ayilAymak) {
            regionId = formValue.ayilAymak;
        }
        if (formValue.village) {
            regionId = formValue.village;
        }
        if (formValue.speciesId) {
            sId = formValue.speciesId;
        }

        this.reCaptchaV3Service.execute(this.siteKey, 'AITSIdentCount', async (token) => {
            this.documentData1 = await this.anonymousService.getIdentCountByRegionAndSpecies(regionId, sId);
        }, {
            useGlobalDomain: false
        });
    }
    private collectRegions(regionId) {
        const region = this.referenceService.getReference('region', regionId);
        if (this.totalRegions.findIndex(r => r.id === region.id) === -1) {
            this.totalRegions.push(region);
        }
        if (region.regionTypeId > 2) {
            this.collectRegions(region.parentId);
        }
    }

}
