import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SubjectService} from '../../../shared/services/subject.service';
import {SelectOptionFormatService} from '../../../shared/services/helpers/select-option-format.service';
import {ReferenceService} from '../../../shared/services/reference.service';
import {AnonymousService} from '../../../shared/services/anonymous.service';
import {Router} from '@angular/router';
import {ReCaptchaV3Service} from 'ngx-captcha';
import {ReCaptchaKey} from '../../../shared/const/reCaptchaKey';

@Component({
    selector: 'app-request-register',
    templateUrl: './request-register.component.html'
})
export class RequestRegisterComponent implements OnInit {

    requestForm: FormGroup;
    objectList: any;
    countries: any;
    siteKey = ReCaptchaKey.siteKey;

    constructor(public translate: TranslateService,
                private referenceService: ReferenceService,
                private subjectService: SubjectService,
                private anonService: AnonymousService,
                private router: Router,
                private reCaptchaV3Service: ReCaptchaV3Service) {
    }

    ngOnInit() {
        const refCountries = this.referenceService.getReferenceList('country');
        this.countries = SelectOptionFormatService.getOptions(refCountries);

        this.requestForm = new FormGroup({
            inn: new FormControl(null, Validators.required),
            objectId: new FormControl(null, Validators.required),
            isImport: new FormControl(false),
            importCountryId: new FormControl(null),
            importTagNumber: new FormControl(null)
        });
    }

    async onInputSubjectINN() {
        if (this.requestForm.value.inn.length === 14) {
            const objects = await this.subjectService.getObjectsBySubjectInn(this.requestForm.value.inn);
            if (objects.length) {
                this.objectList = objects.map(object => {
                    return {
                        label: `${object.sysObjectId} - ${object.topoGeoDetails} / ${object.address}`,
                        value: object.id
                    };
                });
            }
        } else {
            this.requestForm.controls.objectId.reset(null);
            this.objectList = null;
        }
    }

    onSubmit() {

        this.reCaptchaV3Service.execute(this.siteKey, 'AITSRequestRegister', async (token) => {
            const requestData = {
                ...this.requestForm.value,
                captchaToken: token
            };
            await this.anonService.requestRegister(requestData);
            this.router.navigate(['/home']);
        }, {
            useGlobalDomain: false
        });
    }

}
