import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {BreadCrumb} from '../../interfaces';
import {filter, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
    public breadcrumbs: BreadCrumb[];

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    }    

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
        });
    }

    buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
        const label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
        const moduleLabel = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumbModule : '';
        let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

        const lastRoutePart = path.split('/').pop();
        const isDynamicRoute = lastRoutePart.startsWith(':');
        if (isDynamicRoute && !!route.snapshot) {
            const paramName = lastRoutePart.split(':')[1];
            path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
            // label = route.snapshot.params[paramName];
        }

        let nextUrl = path ? `${url}/${path}` : url;
        if(moduleLabel) nextUrl='/'+moduleLabel+nextUrl;           

        const breadcrumb: BreadCrumb = {
            label,
            url: nextUrl
        };        
        const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
        if (route.firstChild) {
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }        
        return newBreadcrumbs;
    }

}
