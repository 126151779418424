import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SelectOptionFormatService {

    public static getOptions(response: []) {
        return {
            ru: response.map((r: any) => (
                    {label: r.nameRu, value: r.id}
                )
            ),
            ky: response.map((r: any) => (
                    {label: r.nameKy, value: r.id}
                )
            )
        };
    }

    public static getRoleOptions(response: []) {
        return {
            ru: response.map((r: any) => (
                    {label: r.code, value: r.id}
                )
            ),
            ky: response.map((r: any) => (
                    {label: r.code, value: r.id}
                )
            )
        };
    }

    public static getMethodOptions(response: []) {
        return {
            ru: response.map((r: any) => (
                    {label: r.method, value: r.id}
                )
            ),
            ky: response.map((r: any) => (
                    {label: r.method, value: r.id}
                )
            )
        };
    }

    public static getNameOptions(response: []) {
        return {
            ru: response.map((r: any) => (
                    {label: r.name, value: r.id}
                )
            ),
            ky: response.map((r: any) => (
                    {label: r.name, value: r.id}
                )
            )
        };
    }
}
