import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';
import {ToastService} from './helpers/toast.service';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class RegionService extends BaseApi {

    regions: any;

    constructor(public http: HttpClient,
                private toastService: ToastService,
                private translate: TranslateService) {
        super(http);
    }

    getAllRegions = (): Promise<any> => this.get('/region');

    getRegionsByUserId = (id: number): Promise<any> => this.get(`/Region/RegionsByUserId?userId=${id}`);

    getRegionById = (id: number): Promise<any> => {
        this.loadFromLocalStorage();
        if (this.regions) {
            return this.regions.filter(r => r.id === id)[0];
        } else {
            return null;
        }
    }

    getRegionsByCurrentUser = async () => {
        const userRegions = JSON.parse(localStorage.getItem('userRegions'));
        if (userRegions) {
            return userRegions;
        } else {
            const regions = await this.get('/Region/ByCurrentUser');
            localStorage.setItem('userRegions', JSON.stringify(regions));

            return regions;
        }
    }

    async saveUserRegionsToLocalStorage() {
        const regions = await this.get('/Region/ByCurrentUser');
        localStorage.setItem('userRegions', JSON.stringify(regions));
    }

    clearUserRegionsFromLocalStorage() {
        localStorage.setItem('userRegions', null);
    }

    getRegionsByParams = async (parentId?: number, level?: number, type?: number, byUser = true): Promise<any> => {
        this.loadFromLocalStorage();
        let returnRegions: any;
        if (byUser) {
            returnRegions = await this.getRegionsByCurrentUser();
            if (returnRegions && returnRegions.length > 0) {
                // bypass for osh and bishkek
                const allCities = this.regions.filter(r => r.regionTypeId === 3);
                if (allCities && allCities.length > 0) {
                    allCities.forEach(c => {
                        const newCity = {
                            id: c.id,
                            nameKy: c.nameKy,
                            nameRu: c.nameRu,
                            parentId : c.id,
                            regionLevel: 6,
                            regionTypeId : 7
                        };
                        this.regions.push(newCity);
                    });
                }

               // need get all villages from users regions
                const allVillages = returnRegions.filter(reg => reg.regionLevel === 8 || reg.regionTypeId === 9);
                if (allVillages && allVillages.length > 0 ) {
                    allVillages.forEach(v => {
                        const ayilAymakParent = returnRegions.filter(aa => aa.id === v.parentId)[0];
                        if (!ayilAymakParent) {
                            const ayilAymakParentRegion = this.regions.filter(aa => aa.id === v.parentId)[0];
                            returnRegions.push(ayilAymakParentRegion);
                        }
                    });
                }
                const allAyilAymaks = returnRegions.filter(reg => reg.regionLevel === 6 || reg.regionTypeId === 7);
                if (allAyilAymaks) {
                    allAyilAymaks.forEach(aa => {
                        const rayonParent = returnRegions.filter(r => r.id === aa.parentId)[0];
                        if (!rayonParent) {
                          const rayonParentRegion = this.regions.filter(r => r.id === aa.parentId)[0];
                          returnRegions.push(rayonParentRegion);
                      }
                    });
                }

                // bypass for osh and bishkek
                const cities = returnRegions.filter(r => r.regionTypeId === 3);
                if (cities && cities.length > 0) {
                    const oldCity = returnRegions.filter(r => r.parentId === r.id);
                    if (oldCity.length < 1) {
                        cities.forEach(c => {
                            const newCity2 = {
                                id: c.id,
                                nameKy: c.nameKy,
                                nameRu: c.nameRu,
                                parentId : c.id,
                                regionLevel: 6,
                                regionTypeId : 7
                            };
                            returnRegions.push(newCity2);
                        });
                    }
                }

                if (parentId) {
                    // check ayil aymak for bishkek and osh
                    if (level && level === 6) {
                        const selectedParentCity = returnRegions.filter(r => r.id === parentId && r.parentId === parentId)[0];
                        if (selectedParentCity) {
                            returnRegions = returnRegions.filter(r => r.id === parentId && r.parentId === parentId);
                        } else {
                            returnRegions = returnRegions.filter(r => r.parentId === parentId && r.regionLevel === 6);
                        }
                    } else {
                       returnRegions = returnRegions.filter(r => r.parentId === parentId && r.id !== parentId);
                    }
                }
                if (level && type) {
                        returnRegions = returnRegions.filter(r => r.regionLevel === level || r.regionTypeId === type);
                    } else if (level) {
                        returnRegions = returnRegions.filter(r => r.regionLevel === level);
                    } else if (type) {
                        returnRegions = returnRegions.filter(r => r.regionTypeId === type);
                    }
                if (returnRegions && returnRegions.length > 0) {
                        if (this.translate.currentLang === 'ru') {
                            returnRegions = returnRegions.sort((r, t) => r.nameRu > t.nameRu ? 1 : -1);
                        } else {
                            returnRegions = returnRegions.sort((r, t) => r.nameKy > t.nameKy ? 1 : -1);
                        }
                    }
            }
            return returnRegions;
        } else {
            if (this.regions) {
                // bypass for osh and bishkek
                const allCities = this.regions.filter(r => r.regionTypeId === 3);
                if (allCities && allCities.length > 0) {
                    allCities.forEach(c => {
                        const newCity = {
                            id: c.id,
                            nameKy: c.nameKy,
                            nameRu: c.nameRu,
                            parentId : c.id,
                            regionLevel: 6,
                            regionTypeId : 7
                        };
                        this.regions.push(newCity);
                    });
                }

                returnRegions = this.regions;
                if (parentId) {
                    // check ayil aymak for bishkek and osh
                    if (level && level === 6) {
                        const selectedParentCity = returnRegions.filter(r => r.id === parentId && r.parentId === parentId)[0];
                        if (selectedParentCity) {
                            returnRegions = returnRegions.filter(r => r.id === parentId && r.parentId === parentId);
                        } else {
                            returnRegions = returnRegions.filter(r => r.parentId === parentId && r.regionLevel === 6);
                        }
                    } else {
                       returnRegions = returnRegions.filter(r => r.parentId === parentId);
                    }
                }
                if (level && type) {
                    returnRegions = returnRegions.filter(r => r.regionLevel === level || r.regionTypeId === type);
                } else if (level) {
                    returnRegions = returnRegions.filter(r => r.regionLevel === level);
                } else if (type) {
                    returnRegions = returnRegions.filter(r => r.regionTypeId === type);
                }
                if (returnRegions && returnRegions.length > 0) {
                    if (this.translate.currentLang === 'ru') {
                        returnRegions = returnRegions.sort((r, t) => r.nameRu > t.nameRu ? 1 : -1);
                    } else {
                        returnRegions = returnRegions.sort((r, t) => r.nameKy > t.nameKy ? 1 : -1);
                    }
                }
                return returnRegions;
            } else {
                let url = '/region/regionsByParams?';
                if (parentId) {
                    url += `parentId=${parentId}&`;
                }
                if (level) {
                    url += `level=${level}&`;
                }
                if (type) {
                    url += `type=${type}`;
                }
                return this.get(url);
            }
        }
    }
    // tslint:disable-next-line: max-line-length
    getUserRegionsByParams = async (parentId?: number, level?: number, type?: number, userRegionList?: any): Promise<any> => {
        this.loadFromLocalStorage();
        let returnRegions: any;
        returnRegions = userRegionList;
        if (returnRegions && returnRegions.length > 0) {
                // bypass for osh and bishkek
                const allCities = this.regions.filter(r => r.regionTypeId === 3);
                if (allCities && allCities.length > 0) {
                    allCities.forEach(c => {
                        const newCity = {
                            id: c.id,
                            nameKy: c.nameKy,
                            nameRu: c.nameRu,
                            parentId : c.id,
                            regionLevel: 6,
                            regionTypeId : 7
                        };
                        this.regions.push(newCity);
                    });
                }

                // need get all villages from users regions
                const allVillages = returnRegions.filter(reg => reg.regionLevel === 8 || reg.regionTypeId === 9);
                console.log("allVillages", allVillages);
                if (allVillages && allVillages.length > 0 ) {
                    allVillages.forEach(v => {
                        const ayilAymakParent = returnRegions.filter(aa => aa.id === v.parentId)[0];
                        if (!ayilAymakParent) {
                            const ayilAymakParentRegion = this.regions.filter(aa => aa.id === v.parentId)[0];
                            returnRegions.push(ayilAymakParentRegion);
                        }
                    });
                }
                const allAyilAymaks = returnRegions.filter(reg => reg.regionLevel === 6 || reg.regionTypeId === 7);
                if (allAyilAymaks) {
                    allAyilAymaks.forEach(aa => {
                        const rayonParent = returnRegions.filter(r => r.id === aa.parentId)[0];
                        if (!rayonParent) {
                          const rayonParentRegion = this.regions.filter(r => r.id === aa.parentId)[0];
                          returnRegions.push(rayonParentRegion);
                      }
                    });
                }


                // bypass for osh and bishkek
                const cities = returnRegions.filter(r => r.regionTypeId === 3);
                if (cities && cities.length > 0) {
                    const oldCity = returnRegions.filter(r => r.parentId === r.id);
                    if (oldCity.length < 1) {
                        cities.forEach(c => {
                            const newCity2 = {
                                id: c.id,
                                nameKy: c.nameKy,
                                nameRu: c.nameRu,
                                parentId : c.id,
                                regionLevel: 6,
                                regionTypeId : 7
                            };
                            returnRegions.push(newCity2);
                        });
                    }
                }

                if (parentId) {
                    // check ayil aymak for bishkek and osh
                    if (level && level === 6) {
                        const selectedParentCity = returnRegions.filter(r => r.id === parentId && r.parentId === parentId)[0];
                        if (selectedParentCity) {
                            returnRegions = returnRegions.filter(r => r.id === parentId && r.parentId === parentId);
                        } else {
                            returnRegions = returnRegions.filter(r => r.parentId === parentId && r.regionLevel === 6);
                        }
                    } else {
                        const selectedParent = returnRegions.filter(r => r.parentId === parentId)[0];
                        if (selectedParent && selectedParent.regionLevel === 6) {
                            returnRegions = returnRegions.filter(r => r.parentId === parentId && r.regionLevel === 8);
                        } else {
                            returnRegions = returnRegions.filter(r => r.parentId === parentId && r.id !== parentId);
                        }
                    }
                }
                if (level && type) {
                        returnRegions = returnRegions.filter(r => r.regionLevel === level || r.regionTypeId === type);
                    } else if (level) {
                        returnRegions = returnRegions.filter(r => r.regionLevel === level);
                    } else if (type) {
                        returnRegions = returnRegions.filter(r => r.regionTypeId === type);
                    }
                if (returnRegions && returnRegions.length > 0) {
                        if (this.translate.currentLang === 'ru') {
                            returnRegions = returnRegions.sort((r, t) => r.nameRu > t.nameRu ? 1 : -1);
                        } else {
                            returnRegions = returnRegions.sort((r, t) => r.nameKy > t.nameKy ? 1 : -1);
                        }
                    }
            }
        return returnRegions;
 }

 getParentRegionsByVillageId = async (villageId?: number) => {
    this.loadFromLocalStorage();
    const returnRegions = {
        village: null,
        ayilaymak: null,
        rayon : null,
        oblast: null
    };
    if (this.regions && villageId) {
        const selVillage = this.regions.filter(f=>f.id === villageId)[0];
        if(selVillage){
            returnRegions.village = selVillage;
            const parentReg1 = this.regions.filter(p=>p.id === selVillage.parentId)[0];
            if(parentReg1){
                returnRegions.ayilaymak = parentReg1;
                if(parentReg1.regionTypeId === 3){
                    returnRegions.rayon = parentReg1;
                }else {
                    const parentReg2 = this.regions.filter(c=>c.id === parentReg1.parentId)[0];
                    if(parentReg2){
                        returnRegions.rayon = parentReg2;
                    }
                }
            }
        }
        return returnRegions;
    } else {
       return returnRegions;
    }
}

loadFromLocalStorage() {
        const references = JSON.parse(localStorage.getItem('references')).references;
        if (references && references['region']) {
            this.regions = references['region'];
        } else {
            const error = {error: {errors: [{message: `Не найдено!`}]}, status: 404};
            this.toastService.showError(error);
        }
    }

editRegion = (regionData: object): Promise<any> => this.put('/region', regionData);
addRegion = (regionData: object): Promise<any> => this.post('/region', regionData);
deleteRegion = (id: string): Promise<any> => this.delete(`/region/${id}`);
}
