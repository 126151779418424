import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {PrimeNgModule} from './primeng.module';
import {MenuCardComponent} from './components/menu-card/menu-card.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {LoaderComponent} from './components/loader/loader.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {HeaderComponent} from './components/header/header.component';
import {InnerLoaderComponent} from './components/inner-loader/inner-loader.component';
import {FooterComponent} from './components/footer/footer.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        MenuCardComponent,
        HeaderComponent,
        BreadcrumbComponent,
        LoaderComponent,
        InnerLoaderComponent,
        SidebarComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        PrimeNgModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    exports: [
        MenuCardComponent,
        BreadcrumbComponent,
        LoaderComponent,
        SidebarComponent,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        PrimeNgModule,
        TranslateModule,
        HeaderComponent,
        InnerLoaderComponent,
        FooterComponent
    ]
})
export class SharedModule {
}
