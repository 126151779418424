import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-menu-card',
    templateUrl: './menu-card.component.html'
})
export class MenuCardComponent {

    @Input() link: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() icon: string;

}
