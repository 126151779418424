import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
    providedIn: 'root'
})
export class SubjectService extends BaseApi {

    getByParamsAndPage = (params: object): Promise<any> => this.post('/subject/ByParamsAndPage', params);

    getByFioOrInn = (params: string): Promise<any> => this.get(`/subject/ByFioOrInn/${params}`);

    getSubject = (id: string): Promise<any> => this.get(`/subject/${id}`);

    getOwnObjects = (id: string): Promise<any> => this.get(`/Subject/OwnedObjects/${id}`);

    getObjectsBySubjectInn = (inn: string): Promise<any> => this.get(`/subject/${inn}/object`);

    addSubject = (subject: object): Promise<any> => this.post('/Subject', subject);

    updateSubject = (subject: object): Promise<any> => this.put('/Subject', subject);

    deleteSubject = (id: string): Promise<any> => this.delete(`/Subject/${id}`);

    exportToExl = (filterData: object): Promise<any> => this.post('/Subject/Export', filterData, {responseType: 'blob'});

    deactivate = (subjectId: string): Promise<any> => this.post(`/Subject/Deactivate/${subjectId}`);
}
