import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

    user: any;

    constructor(public translate: TranslateService) { }

    ngOnInit() {
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    setLang(lang: string) {
        this.translate.use(lang);
    }
}
