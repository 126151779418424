import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CrudAccessService} from '../../services/helpers/crud-access.service';
import {systemData} from '../../const/system-data';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

    systemVersion: string;
    mainMenu: MenuItem[];
    tempMenu: MenuItem[];
    @Input() sidebar: boolean;

    constructor(
        private translate: TranslateService,
        private crudAccessService: CrudAccessService) {
    }

    sidebarToggle() {
        this.sidebar = !this.sidebar;
    }

    setMenu(translations) {
        this.mainMenu = [];

        let identificationMenu: MenuItem;
        identificationMenu = {label: translations.menu_identification, items: []};
        this.tempMenu = [];
        let identificationIsMenu = false;
        if (this.crudAccessService.checkRouteRights(['animal#get'])) {
            this.tempMenu.push({label: translations.global_animals, routerLink: '/identification/animal'});
            identificationIsMenu = true;

        }
        if (this.crudAccessService.checkRouteRights(['subject#get'])) {
            this.tempMenu.push({label: translations.global_subjects, routerLink: '/identification/subject'});
            identificationIsMenu = true;
        }
        if (this.crudAccessService.checkRouteRights(['object#get'])) {
            this.tempMenu.push({label: translations.global_objects, routerLink: '/identification/object'});
            identificationIsMenu = true;
        }
        if (this.crudAccessService.checkRouteRights(['eartag#get'])) {
            this.tempMenu.push({label: translations.earTags, routerLink: '/identification/eartag'});
            identificationIsMenu = true;
        }
        identificationMenu.items = this.tempMenu;
        if (identificationIsMenu) {
            this.mainMenu.push(identificationMenu);
        }

        const healthMenu = {label: translations.menu_health, items: []};
        if (this.crudAccessService.checkRouteRights(['plan#get'])) {
            healthMenu.items.push({
                label: translations.menu_health_planning,
                routerLink: '/health/planning'
            });
        }
        if (this.crudAccessService.checkRouteRights(['vschema#admin'])) {
            healthMenu.items.push({
                label: translations.menu_health_vaccinationSchema,
                routerLink: '/health/vaccinationSchema'
            });
        }
        if (this.crudAccessService.checkRouteRights(['checklist#admin'])) {
            healthMenu.items.push({label: translations.global_checklists, routerLink: '/health/checklist'});
        }
        if (this.crudAccessService.checkRouteRights(['healthStatus#get'])) {
            healthMenu.items.push({label: translations.animal_healthStatus, routerLink: '/health/healthstatus'});
        }
        if (healthMenu.items.length) {
            this.mainMenu.push(healthMenu);
        }

        const profileMenu = {label: translations.menu_profile, items: []};
        if (this.crudAccessService.checkRouteRights(['animal#identity'])) {
            profileMenu.items.push(
                {
                    label: translations.menu_profile_identity,
                    routerLink: '/profile/identity'
                },
                {
                    label: translations.menu_profile_health,
                    routerLink: '/profile/health'
                },
                {
                    label: translations.menu_profile_movements,
                    routerLink: '/profile/movements'
                },
                {
                    label: translations.animal_prohibition,
                    routerLink: '/profile/prohibitions'
                }
            );
        }
        if (profileMenu.items.length) {
            this.mainMenu.push(profileMenu);
        }

        const movementsMenu = {label: translations.menu_movements, items: []};
        if (this.crudAccessService.checkRouteRights(['movement#departure'])) {
            movementsMenu.items.push({
                label: translations.menu_movements_departure,
                routerLink: '/movements/departure'
            });
        }
        if (this.crudAccessService.checkRouteRights(['movement#arrival'])) {
            movementsMenu.items.push({
                label: translations.menu_movements_arrival,
                routerLink: '/movements/arrival'
            });
        }
        if (this.crudAccessService.checkRouteRights(['movement#history'])) {
            movementsMenu.items.push({
                label: translations.menu_movements_history,
                routerLink: '/movements/history'
            });
        }
        if (movementsMenu.items.length) {
            this.mainMenu.push(movementsMenu);
        }

        const productsMenu = {label: translations.menu_products, items: []};
        if (this.crudAccessService.checkRouteRights(['product#departure'])) {
            productsMenu.items.push({
                label: translations.product_slaughter,
                routerLink: '/products/slaughter'
            });
        }
        if (this.crudAccessService.checkRouteRights(['product#laboratory'])) {
            productsMenu.items.push({
                label: translations.global_laboratoryVSE,
                routerLink: '/products/laboratoryvse'
            });
        }
        if (this.crudAccessService.checkRouteRights(['product#arrivalm'])) {
            productsMenu.items.push({
                label: translations.global_market,
                routerLink: '/products/market'
            });
        }
        if (productsMenu.items.length) {
            this.mainMenu.push(productsMenu);
        }


        let laboratoryMenu: MenuItem;
        laboratoryMenu = {label: translations.menu_laboratory, items: []};
        this.tempMenu = [];
        let laboratoryMenuIsMenu = false;
        if (this.crudAccessService.checkRouteRights(['laboratory#employee'])) {
            this.tempMenu.push({label: translations.menu_laboratory_checklist, routerLink: '/laboratory/checklist'});
            laboratoryMenuIsMenu = true;
        }
        if (this.crudAccessService.checkRouteRights(['checklist#result'])) {
            this.tempMenu.push({
                label: translations.menu_laboratory_result,
                routerLink: '/laboratory/checklist-result'
            });
            laboratoryMenuIsMenu = true;
        }
        if (this.crudAccessService.checkRouteRights(['laboratory#admin'])) {
            this.tempMenu.push({label: translations.menu_admin_references, routerLink: '/laboratory/references'});
            laboratoryMenuIsMenu = true;
        }
        if (this.crudAccessService.checkRouteRights(['laboratory#aits'])) {
            this.tempMenu.push({label: translations.menu_laboratory_checklistaits, routerLink: '/laboratory/checklist-aits'});
            laboratoryMenuIsMenu = true;
        }

        laboratoryMenu.items = this.tempMenu;
        if (laboratoryMenuIsMenu) {
            this.mainMenu.push(laboratoryMenu);
        }

        const reportsMenu = {label: translations.menu_reports, items: []};
        if (this.crudAccessService.checkRouteRights(['report#get'])) {
            reportsMenu.items.push({label: translations.menu_reports_generic, routerLink: '/reports/generic'});
            reportsMenu.items.push({label: translations.menu_reports_system, routerLink: '/reports/system'});
        }
        if (reportsMenu.items.length) {
            this.mainMenu.push(reportsMenu);
        }

        const adminMenu = {label: translations.menu_admin, items: []};
        // tslint:disable-next-line: max-line-length
        if (this.crudAccessService.checkRouteRights(['medicine#admin', 'breed#admin', 'laboratory#add', 'species#admin', 'disease#admin'])) {
            adminMenu.items.push({label: translations.menu_admin_references, routerLink: '/admin/references'});
        }
        if (this.crudAccessService.checkRouteRights(['task#reassign'])) {
            adminMenu.items.push({label: translations.global_tasks, routerLink: '/admin/tasks'});
        }
        if (this.crudAccessService.checkRouteRights(['user#admin', 'role#admin', 'roleaccess#admin', 'profile#admin'])) {
            adminMenu.items.push({label: translations.menu_admin_account, routerLink: '/admin/account'});
        }
        if (this.crudAccessService.checkRouteRights(['report#add'])) {
            adminMenu.items.push({label: translations.menu_admin_reports, routerLink: '/admin/reports'});
        }
        if (this.crudAccessService.checkRouteRights(['error#get'])) {
            adminMenu.items.push({label: translations.errors_system, routerLink: '/admin/errors'});
        }
        if (this.crudAccessService.checkRouteRights(['system-params#get'])) {
            adminMenu.items.push({label: translations.system_params, routerLink: '/admin/system-params'});
        }
        if (this.crudAccessService.checkRouteRights(['cache#clear'])) {
            adminMenu.items.push({label: translations.cache_clear, routerLink: '/admin/clear-cache'});
        }
        if (adminMenu.items.length) {
            this.mainMenu.push(adminMenu);
        }
    }

    ngOnInit() {
        this.systemVersion = systemData.clientVersion;

        this.translate.getTranslation(this.translate.currentLang)
            .toPromise().then(translations => {
            this.setMenu(translations);
        });


        this.translate.onLangChange.subscribe(translate => (
            this.setMenu(translate.translations)
        ));
    }
}
